import { useMemo } from 'react';
import { window } from 'browser-monads';

import generateRandomId from '../helpers/generateRandomId';

const useBreadcrumbs = (links, needSlash=true) => {
  return useMemo(() => {
    const path = window.location.pathname;
    const pathStringsArr = path.split('/');
    const object = {
      home: {
        _key: generateRandomId(),
        label: 'Home',
        url: '/',
      }
    };

    for (let key of links) {
      object[key.url] = {
        _key: key._key,
        label: key.urlLabel,
        url: needSlash ? key.url : `/${key.url}`,
      }
    }
    const arr = [object.home];
    for (let key of pathStringsArr) {
      const pathKey = needSlash ? `/${key}` : key;
      if(key && object[pathKey]) arr.push(object[pathKey]);
    }
    return arr;
  }, [links, needSlash]);
}

export default useBreadcrumbs;

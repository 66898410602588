import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

import BlockContent from "../richText";
import GatsbyImage from "../gatsbyImage";

import * as styles from "./aboutAuthor.module.scss";
import generateRandomId from "../../helpers/generateRandomId";

function AboutAuthor({ author, vertical = false }) {
  const { bio, profileImage } = author;

  return (
    <div
      className={cx({
        [styles.aboutAuthorWrap]: true,
        [styles.horizontal]: !vertical,
        [styles.vertical]: vertical,
      })}
    >
      {profileImage?.image && (
        <div className={styles.authorImgContainer}>
          <div className={styles.authorBackgroundImg}>
            <StaticImage
              alt="DottedBackgroundImage"
              placeholder="blurred"
              src="../../../static/images/author_dotted_background.svg"
            />
          </div>
          <div>
            <GatsbyImage
              alt={profileImage?.alt || generateRandomId()}
              height={177}
              width={177}
              img={profileImage.image.asset}
            />
          </div>
        </div>
      )}
      <div className={styles.info}>
        <h3>About the author</h3>
        {(bio ?? []).map((blocks) => (
          <BlockContent blocks={blocks} key={blocks._key} />
        ))}
      </div>
    </div>
  );
}

AboutAuthor.propTypes = {
  author: PropTypes.object.isRequired,
  vertical: PropTypes.bool,
};

AboutAuthor.defaultProps = {
  vertical: false,
};

export default AboutAuthor;
